import * as React from "react"
import Logo from "./logo"
import RenderRow from "../components/RenderRow"
import gsap from "gsap"
import { StaticImage } from "gatsby-plugin-image"
import PageLinkFade from "./PageTransitionFade"
export default class HeroSection extends React.Component {
  constructor(props) {
    super(props)
    this.changeLetters = this.changeLetters.bind(this)
    this.changeWordsAfterLoad = this.changeWordsAfterLoad.bind(this)
    this.radomiseLocationOrder = this.radomiseLocationOrder.bind(this)
    this.windowRezsier = this.windowRezsier.bind(this)
    const videoUrl = this.props.heroVideo
    const locations = this.props.locations.map(item => item.locations.map(location => location.locationName))
    this.state = {
      bodyHeight: null,
      paddingBottom: "40px",
      list: [],
      videoUrl,
      mobile: null,
      loc: locations,
    }
  }

  windowRezsier() {
    let width = window.innerWidth
    let row1 = document.querySelector(`#row-1`)
    let row2 = document.querySelector(`#row-2`)
    let row3 = document.querySelector(`#row-3`)
    if (width < 600) {
      let loactions1 = row1.querySelectorAll(".item-location")
      for (let i = 0; i < 11; i++) {
        loactions1[i].style.display = "none"
        loactions1[i].classList.remove("shown")
      }
      let loactions2 = row2.querySelectorAll(".item-location")
      for (let i = 0; i < 11; i++) {
        loactions2[i].style.display = "none"
        loactions2[i].classList.remove("shown")
      }
      let loactions3 = row3.querySelectorAll(".item-location")
      for (let i = 0; i < 9; i++) {
        loactions3[i].style.display = "none"
        loactions3[i].classList.remove("shown")
      }
    } else if (width < 900) {
      let row1 = document.querySelector(`#row-1`)
      let row2 = document.querySelector(`#row-2`)
      let row3 = document.querySelector(`#row-3`)
      let loactions1 = row1.querySelectorAll(".item-location")

      for (let i = 0; i < 7; i++) {
        loactions1[i].style.display = "none"
        loactions1[i].classList.remove("shown")
      }
      let loactions2 = row2.querySelectorAll(".item-location")
      for (let i = 0; i < 7; i++) {
        loactions2[i].style.display = "none"
        loactions2[i].classList.remove("shown")
      }
      let loactions3 = row3.querySelectorAll(".item-location")
      for (let i = 0; i < 6; i++) {
        loactions3[i].style.display = "none"
        loactions3[i].classList.remove("shown")
      }
    } else if (width < 1200) {
      let row1 = document.querySelector(`#row-1`)
      let row2 = document.querySelector(`#row-2`)
      let row3 = document.querySelector(`#row-3`)
      let loactions1 = row1.querySelectorAll(".item-location")

      for (let i = 0; i < 5; i++) {
        loactions1[i].style.display = "none"
        loactions1[i].classList.remove("shown")
      }
      let loactions2 = row2.querySelectorAll(".item-location")
      for (let i = 0; i < 5; i++) {
        loactions2[i].style.display = "none"
        loactions2[i].classList.remove("shown")
      }
      let loactions3 = row3.querySelectorAll(".item-location")
      for (let i = 0; i < 4; i++) {
        loactions3[i].style.display = "none"
        loactions3[i].classList.remove("shown")
      }
    }
  }

  componentDidMount() {
    this.windowRezsier()
    window.addEventListener("resize", () => {
      this.windowRezsier()
    })
    this.setState({ bodyHeight: window.innerHeight })

    if (window.innerWidth < 768) {
      this.setState({ paddingBottom: "30px" })

      this.setState({ mobile: true }, () => {})
    } else if (window.innerWidth >= 768) {
      this.setState({ mobile: false }, () => {})
    }

    window.scrollY = 0
    this.changeToBodyHeight() //done
    let count = 0 //done
    let visible = this.radomiseLocationOrder() //done
    let lengthV = visible.length //done
    setTimeout(() => {
      const event = new Event("page-loaded")
      document.dispatchEvent(event)
    }, 10000)

    if (!this.props.internal) {
      let timer = setInterval(() => {
        if (count >= lengthV) {
          clearInterval(timer)
          setTimeout(() => {
            this.removeloaderBackground()
            let tl = gsap.timeline()
            tl.to("#nav-bar", { y: "0%", duration: 1, ease: "ease" })
            this.changeBody()
            setTimeout(() => {
              this.changeWordsAfterLoad()
            }, 2000)
          }, 1000)
        } else {
          visible[count].style.clientWidth = `${visible[count].style.clientWidth + 5}`
          const width = visible[count].scrollWidth
          this.changeLetters(visible[count], width)
          visible[count].classList.replace("o-0-v-h-t", "o-1-5-v-v")

          count++
        }
      }, 35)
    } else {
      this.removeloaderBackground()
      const words = document.querySelectorAll("#locations")
      words.forEach(word => {
        word.classList.remove("o-0-v-h-t")
      })

      let tl = gsap.timeline({ delay: 2 })
      tl.to("#nav-bar", { y: "0%", duration: 1, ease: "ease" })
      this.changeBody()
      setTimeout(() => {
        this.changeWordsAfterLoad()
      }, 1000)
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.timer2)
    window.removeEventListener("resize", () => {
      this.windowRezsier()
    })
  }

  changeWordsAfterLoad() {
    let visible = this.radomiseLocationOrder()
    let count = 0
    let length = visible.length

    this.setState({
      timer2: setInterval(() => {
        if (count === length - 1) {
          visible = this.radomiseLocationOrder()
          count = 0
        } else {
          visible[count].style.clientWidth = `${visible[count].style.clientWidth + 5}`
          const width = visible[count].scrollWidth
          this.changeLetters(visible[count], width, true)
          count++
        }
      }, Math.floor(Math.random() * 1000) + 1000),
    })
  }

  radomiseLocationOrder() {
    const locations = document.querySelectorAll("#locations.shown")
    const visible = []
    const hidden = []
    locations.forEach(location => {
      hidden.push(location)
    })
    const length = hidden.length
    for (let i = length; i >= 1; i--) {
      const randomIndex = Math.floor(Math.random() * i)
      const el = hidden.splice(randomIndex, 1)
      visible.push(el[0])
    }

    return visible
  }

  changeToBodyHeight() {
    const body = document.querySelector("body")
    body.style.height = "100vh"
    body.style.overflow = "hidden"
  }

  removeloaderBackground() {
    const loader = document.querySelector("#loader")

    loader.classList.add("fade-out-1")
    setTimeout(() => {
      const event = new Event("page-loaded")
      document.dispatchEvent(event)
    }, 1000)
  }
  fadeInNavbar() {
    let tl = gsap.timeline()
    tl.to("#Nav-bar", { x: "100%", duration: 1, ease: "ease" })
  }

  changeBody() {
    const body = document.querySelector("body")
    body.style.height = "auto"
    body.style.overflow = "auto"
    window.scrollY = 0
  }

  changeLetters(el, width, afterLoad) {
    el.style.width = `${width}px`

    let newStr = ""
    let letters = "abcdefghijklmnopqrstuvwxyz99072"
    let oldStr = el.childNodes[0].lastChild.textContent
    const length = oldStr.length
    let count = 0
    let timer

    let delay = 30
    setTimeout(() => {
      timer = setInterval(change, delay)
    }, 100)

    function change() {
      clearInterval(timer)
      delay += 5

      newStr = ""
      if (count !== 10) {
        count++
        for (let i = 0; i < length; i++) {
          if (oldStr[i] === " ") {
            newStr += " "
          } else {
            newStr += letters[Math.floor(Math.random() * 26)]
          }
        }
        el.childNodes[0].lastChild.textContent = newStr
        timer = setInterval(change, delay)
      } else {
        clearInterval(timer)

        el.childNodes[0].lastChild.textContent = oldStr
      }
    }
  }

  render() {
    const Rows = this.state.loc.map((los, index) => {
      return <RenderRow locations={los} id={`row-${index + 1}`} key={index} />
    })

    let video

    if (this.state.mobile === true) {
      video = (
        <>
          <StaticImage alt='' src='../images/03_t4s.jpeg' className='w-100-p h-100-p z-2 bg-image' placeholder='dominantColor' />

          <video className='bg-image z-3 ' muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
        </>
      )
    } else if (this.state.mobile === false) {
      video = (
        <video className='bg-image z-3 ' muted='muted' autoPlay loop={true} playsInline={true}>
          <source src={this.state.videoUrl} type='video/mp4' />
          <track kind='captions'></track>
        </video>
      )
    }

    return (
      <div className=' h-100-vh h--69-px w-100-vw flex flex-center-h flex-center-v z-1 relative'>
        {video}

        <PageLinkFade to='/memberships' className='absolute auto m-b-69 flex flex-c-h flex-c-v'>
          <Logo className='f-white w-30 h-55 z-6  auto ' />
        </PageLinkFade>
        <div id='loader' className='  h-100vh-x  m-h-100vh  w-100-p back-black z-5 fixed t-0 '></div>
        <div className='absolute b-0  flex-c w-100-p p-l-40 p-r-40  p-t-45 z-6 ' style={{ paddingBottom: this.state.paddingBottom }}>
          {Rows}
        </div>
      </div>
    )
  }
}
//45 80
