import * as React from "react"
import PageLinkFade from "../components/PageTransitionFade"
export default class LogoTwo4Seven extends React.Component {
  render() {
    return (
      <PageLinkFade to="/">
        <svg
          width="192"
          height="36"
          viewBox="0 0 192 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.2896 14.5046V23.0786H44.4755V14.5046H39.8839V12.9214H50.8813V14.5046H46.2896Z"
            fill="black"
          />
          <path
            d="M65.927 12.9214L68.6482 21.2539L71.5118 12.9214H73.3957L69.7668 23.0786H67.5853L64.9332 14.8743L62.2566 23.0786H60.0721L56.4573 12.9214H58.3582L61.2219 21.2539L63.9571 12.9214H65.927Z"
            fill="black"
          />
          <path
            d="M90.5173 17.9985C90.5173 21.0123 87.9959 23.2341 84.551 23.2341C81.1062 23.2341 78.5825 21.0123 78.5825 17.9985C78.5825 14.9846 81.1062 12.7657 84.551 12.7657C87.9959 12.7657 90.5173 14.9846 90.5173 17.9985ZM80.4411 17.9985C80.4411 20.1647 82.0994 21.6339 84.551 21.6339C87.0027 21.6339 88.6609 20.1647 88.6609 17.9985C88.6609 15.8351 87.0027 14.363 84.551 14.363C82.1001 14.363 80.4411 15.8351 80.4411 17.9985Z"
            fill="black"
          />
          <path
            d="M103.282 23.0786V20.6041H96.4229V19.1876L102.645 12.9214H105.027V19.1039H107.762V20.6034H105.027V23.0779H103.282V23.0786ZM103.282 19.1039V14.3905L98.5487 19.1039H103.282Z"
            fill="black"
          />
          <path
            d="M114.488 19.4565C115.635 21.1538 117.479 21.6643 119.01 21.6643C120.838 21.6643 122.157 21.056 122.157 20.1507C122.157 19.0727 121.306 18.9897 118.328 18.6512C115.734 18.3541 113.892 17.9288 113.892 15.9485C113.892 14.1097 115.706 12.7657 118.541 12.7657C120.793 12.7657 122.835 13.6126 123.701 14.9431L122.396 16.0322C121.659 14.9431 120.242 14.3349 118.469 14.3349C116.458 14.3349 115.606 15.0291 115.606 15.7655C115.606 16.5708 116.257 16.7679 118.923 17.0791C122.015 17.4347 123.927 17.7732 123.927 19.9373C123.927 22.1036 121.731 23.2341 118.965 23.2341C116.458 23.2341 114.132 22.2006 113.183 20.6596L114.488 19.4565Z"
            fill="black"
          />
          <path
            d="M140.4 12.9214V14.5046H132.35V17.0517H139.593V18.6379H132.35V21.4961H140.4V23.0794H130.564V12.9221H140.4V12.9214Z"
            fill="black"
          />
          <path
            d="M151.423 23.0786L146.205 12.9214H148.148L152.486 21.3813L156.825 12.9214H158.75L153.522 23.0786H151.423Z"
            fill="black"
          />
          <path
            d="M174.872 12.9214V14.5046H166.822V17.0517H174.066V18.6379H166.822V21.4961H174.872V23.0794H165.035V12.9221H174.872V12.9214Z"
            fill="black"
          />
          <path
            d="M192 23.0786H190.414L183.468 15.1988V23.0786H181.723V12.9214H183.596L190.258 20.4619V12.9214H192V23.0786Z"
            fill="black"
          />
          <path
            d="M15.022 1.88475H13.9725L13.2191 3.74358H12.0307L12.9905 0.964603L12.9882 0.963862H11.9416L10.9819 3.74358H10.469V0H9.47733V3.74358H8.96517L8.00839 0.973494H6.95884L7.91561 3.74358H6.72799L5.97534 1.88698H4.91836L4.90723 1.89216L6.05922 4.73337H8.25779H11.6885H13.8871L15.0398 1.89216L15.022 1.88475Z"
            fill="black"
          />
          <path
            d="M19.946 6.49963H0.00148452V26.0043C0.00148452 26.0191 0 26.034 0 26.0495C0 26.0643 0.000742261 26.0792 0.00148452 26.0947V26.1118H0.00222678C0.0356285 31.5704 4.49365 36.0008 9.97006 36.0008C15.4465 36.0008 19.9045 31.5704 19.9386 26.1118H19.9468V6.49963H19.946ZM10.4659 7.48943H12.1293C11.9571 7.59463 11.7908 7.70872 11.632 7.83541C11.1673 8.2051 10.7776 8.64591 10.4659 9.13192V7.48943ZM9.47348 16.0294H1.69755L9.47423 8.31771V16.0294H9.47348ZM10.4659 15.081C10.6611 15.4085 10.8712 15.7249 11.0953 16.0294H10.4659V15.081ZM11.0293 14.0587C10.0985 12.248 10.624 9.90464 12.2503 8.60961C14.2329 7.03083 17.219 7.78799 18.9544 9.40826V16.0301H12.3542C11.8457 15.4152 11.3915 14.7647 11.0293 14.0587ZM18.9544 17.0199V22.6882C18.4222 21.8911 17.7386 21.2458 17.0638 20.6131C16.9243 20.4819 16.784 20.3508 16.6437 20.216C16.2125 19.804 15.7604 19.3929 15.324 18.9958C14.6211 18.3564 13.907 17.7074 13.2434 17.0199H18.9544ZM8.90194 7.48943C7.2801 9.09784 2.85771 13.483 0.993146 15.3322V7.48943H8.90194ZM0.993146 21.7221V17.0191H5.78741L9.47423 17.0162V34.9947C4.76829 34.7376 1.0169 30.8473 0.993888 26.0932V26.0043V21.7221H0.993146ZM10.4659 34.9954V17.0199H11.894C12.7476 17.9926 13.7111 18.8683 14.656 19.7277C15.0887 20.1211 15.5355 20.5271 15.9579 20.9309C16.1004 21.0672 16.2429 21.2013 16.3847 21.3339C17.7601 22.6252 18.9477 23.7402 18.9477 26.0495C18.9477 30.8236 15.1859 34.7376 10.4659 34.9954ZM18.9544 7.48943V8.11768C18.6493 7.89394 18.3324 7.68427 18.0043 7.48943H18.9544Z"
            fill="black"
          />
        </svg>
        </PageLinkFade >
    )
  }
}
