import * as React from "react"



export default class SplitSection extends React.Component {
  
  render() {
    let section;
    if(!this.props.alStart){
      section = <div className={ `back-white flex  w-100-p-b  relative flex-r w-100-p  h-100-p flex-grow-1 ma-h-100vh-50  r-c-l ${this.props.wrapperClassName}`}>
      <div  className={`w-50-p  p-40 flex flex-r al-end w-100-p-b  ${this.props.subWrapperClassName}`}>
        {this.props.children}
        
      </div>
      <div className={`w-50-p p-40 b-l-s-50 d-n-b w-100-p-b  ` + this.props.classRight}>
        {this.props.right}
      </div>
    </div>
    }else{
      section =<div className={ `back-white flex flex-r  w-100-p-b w-100-p r-c-l relative  ma-h-100vh-50 flex-grow-1   ${this.props.wrapperClassName}`}>
      <div  className={`w-50-p  p-40 flex flex-r al-start   w-100-p-b ${this.props.subWrapperClassName}`}>
        {this.props.children}
        
      </div>
      <div className={`w-50-p p-40 ma-h-100vh-50 b-l-s-50 d-n-b w-100-p-b `  + this.props.classRight}>
        {this.props.right}
      </div>
    </div>
    }
    return (
      <div className={` m-h-100vh-50 ma-h-100vh-50 relative  w-100-p-b  flex-c flex ${this.props.className}`}>
        {section}
      </div>

    )
  }
}

