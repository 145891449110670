import * as React from "react"
export default class RenderRow extends React.Component {
    constructor(props){
        super(props)
        this.locations = props.locations
    }
    render()
    {

        
        const listItems = this.locations.map((location,index)=>{
           return( <div
         
            key={location +index}
            id="locations"
            className="relative flex-center-v o-0-v-h-t over-v flex-center-h flex item-location shown"

             
          >
            <p className="nav  alt-11 w-n-w h-100-p w-100-p  over-v  ">{location}</p>
          </div>)
        })
        return(
            <div className="flex flex-r flex-s-b relative"   id={this.props.id}>
            {listItems}
            </div> 
        )
        } 
    
}