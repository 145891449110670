import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import HeroSection from "../components/HeroSection"
import SplitSection from "../components/SplitSection"
import Slider from "../components/Slider"
import Logo from "../components/logo"
import TextWoLogo from "../components/TextWoLogo"

import { GatsbyImage } from "gatsby-plugin-image"
import LinktoFormButton from "../components/LinktoFormButton"
import Footer from "../components/footer"

import gsap from "gsap"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: false,
    }

    this.images = ["../images/Rectangle31.png", "../images/Rectangle26.png"]
  }
  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ mobile: true })
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        this.setState({ mobile: true })
      }
    })
  }

  invertNav() {
    let tl = gsap.timeline()
    tl.to("#navbar", { background: "white" })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsHomepage {
              locationNames {
                locations {
                  locationName
                }
              }
              heroVideo
              firstSplitSectionTitle
              firstSplitSectionParagraph
              firstSplitSectionImage {
                gatsbyImageData
              }
              slider {
                sliderParagraph
                sliderTitle
                videoLink
              }
              fullLengthSliderVideo
              secondSplitSectionTitle
              secondSplitSectionSubtitle
              secondSplitSectionParagraph
              secondSplitSectionImage {
                gatsbyImageData
              }
              thirdSplitSectionTitle
              thirdSplitSectionSubtitle
              thirdSplitSectionParagraph
              thirdSplitSectionImage {
                gatsbyImageData
              }
              bannerHeading
              bannerImage {
                gatsbyImageData
              }
              useLinkInstead
              link
            }
          }
        `}
        render={({ datoCmsHomepage }) => (
          <Layout>
            <Header className='m-a-1-n-b ' isLink={datoCmsHomepage.useLinkInstead} link={datoCmsHomepage.link} />
            <Seo title='Two4Seven' />
            <div data-scroll-container id='scroll-container' className='data-scroll-container'>
              <section data-scroll-section data-scroll data-scroll-call='invertNavTran' data-scroll-repeat='true' data-scroll-offset='100%'>
                <HeroSection heroVideo={datoCmsHomepage.heroVideo} locations={datoCmsHomepage.locationNames} internal={this.props.entry.state.internal} />
              </section>

              <section data-scroll-section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
                <SplitSection
                  right={
                    <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                      <GatsbyImage image={datoCmsHomepage.firstSplitSectionImage.gatsbyImageData} alt='' objectFit='cover' className='w-100-p h-100-p' imgClassName='w-100-p h-100-p' placeholder='dominateColor' />
                    </div>
                  }
                >
                  <div data-scroll data-scroll-call='fade-in' className='o-0-5' data-scroll-offset='30%'>
                    <Logo className='f-2A2A2A h-30 ' />
                    <div className='p-t-100'>
                      <h2 className='m-b-40'>{datoCmsHomepage.firstSplitSectionTitle}</h2>
                      <p className='body-text m-w-450' dangerouslySetInnerHTML={{ __html: datoCmsHomepage.firstSplitSectionParagraph }} />
                      <LinktoFormButton isLink={datoCmsHomepage.useLinkInstead} text="I'm Interested" className='m-t-30' to={datoCmsHomepage.link ?? '/contact' } />
                    </div>
                  </div>
                </SplitSection>
              </section>

              <section data-scroll-section className='slider-section relative' data-scroll data-scroll-call='invertNavTran' data-scroll-repeat='true' data-scroll-offset='100%'>
                <Slider images={this.images} sliderVideo={datoCmsHomepage.fullLengthSliderVideo} sliderInfo={datoCmsHomepage.slider} mobile={this.state.mobile} />
              </section>

              <section data-scroll-section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
                <SplitSection
                  className='h-100-vh'
                  right={
                    <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                      <GatsbyImage alt='' image={datoCmsHomepage.secondSplitSectionImage.gatsbyImageData} className='w-100-p h-100-p' placeholder='dominantColor' />
                    </div>
                  }
                >
                  <div data-scroll data-scroll-call='fade-in' className='o-0-5' data-scroll-offset='30%'>
                    <TextWoLogo isLink={datoCmsHomepage.useLinkInstead} to={datoCmsHomepage.link ?? '/contact' } title={datoCmsHomepage.secondSplitSectionTitle} subTitle={datoCmsHomepage.secondSplitSectionSubtitle} text={datoCmsHomepage.secondSplitSectionParagraph}  />
                  </div>
                </SplitSection>
              </section>
              <section data-scroll-section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
                <SplitSection
                  className='h-100-vh  '
                  wrapperClassName='m-flex-c '
                  classRight=' d-b-f d-n-b'
                  right={
                    <div data-scroll data-scroll-call='fade-in' className='o-0-5 h-100-p flex al-end  flex-r ' data-scroll-offset='30%'>
                      <TextWoLogo isLink={datoCmsHomepage.useLinkInstead} to={datoCmsHomepage.link ?? '/contact' } title={datoCmsHomepage.thirdSplitSectionTitle} subTitle={datoCmsHomepage.thirdSplitSectionSubtitle} text={datoCmsHomepage.thirdSplitSectionParagraph}  />
                    </div>
                  }
                >
                  <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                    <GatsbyImage alt='' quality={100} image={datoCmsHomepage.thirdSplitSectionImage.gatsbyImageData} className='w-100-p h-100-p' placeholder='dominantColor' />
                  </div>
                </SplitSection>
              </section>
              <section data-scroll-section data-scroll data-scroll-call='invertNavTran' data-scroll-repeat='true' data-scroll-offset='100%'>
                <div className='h-100-vh w-100-vw relative flex'>
                  <GatsbyImage alt='' image={datoCmsHomepage.bannerImage.gatsbyImageData} className='bg-image absolute c-r' placeholder='dominantColor' objectPosition='50% 10%' />
                  <div className='w-100-p h-100-p z-3 absolute flex flex-center-h flex-center-v flex-c'>
                    <div className='m-b-40 m-b-0-br '>
                      <p className='text-large-orbikular c-2A2A2A ' dangerouslySetInnerHTML={{ __html: datoCmsHomepage.bannerHeading }} />
                    </div>
                    <LinktoFormButton isLink={datoCmsHomepage.useLinkInstead}  text="I'm Interested" className='m-t-50' to={datoCmsHomepage.link ?? '/contact' } />
                  </div>
                </div>
              </section>
              <section data-scroll-section>
                <Footer />
              </section>
            </div>
          </Layout>
        )}
      />
    )
  }
}

export default IndexPage
