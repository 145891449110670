import * as React from "react"

import { gsap } from "gsap"
import PageLinkFade from "../components/PageTransitionFade"
import LinktoFormButton from "./LinktoFormButton"

export default class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.navVideo = this.navVideo.bind(this)
    this.hover = this.hover.bind(this)
    this.hoverOut = this.hoverOut.bind(this)
    const fullVideo = this.props.sliderVideo
    const sections = this.props.sliderInfo.map(info => {
      return { name: info.sliderTitle, page: info.sliderTitle.toLowerCase().replace(/\s/g, ""), text: info.sliderParagraph, short: info.sliderTitle.split(" ")[1].toLowerCase(), videoUrl: info.videoLink }
    })

    this.state = {
      sectionNum: 1,
      lastSection: false,
      currentTime: 0,
      currentSectionMobile: 0,
      fullVideo,
      sections,
    }
  }

  hoverOut(e) {
    let tl = gsap.timeline()
    tl.add("one")
    tl.to("#cursor", { opacity: 0, duration: 0.25, ease: "power1.out" })
    tl.to("#cursor", { visibility: "hidden" })
  }

  hover(e) {
    let tl = gsap.timeline()
    tl.add("one")
    tl.add("two")
    tl.to("#cursor", { visibility: "visible" }, "one")
    tl.to("#cursor", { opacity: 1, duration: 0.25, ease: "power1.out" }, "two")
  }

  transiton(lastSection, currentSection) {
    let tl = gsap.timeline({ paused: true })
    tl.add("start")
    tl.add("one")
    tl.add("two")
    tl.add("three")

    function first() {
      let tl = gsap.timeline()
      tl.add("start")

      tl.to(`[data-cursor="${lastSection}"]`, { opacity: 0, duration: 0.5, ease: "power1.out" }, "start")
      tl.to(`[data-cursor="${currentSection}"]`, { opacity: 1, duration: 0.5, ease: "power1.out" }, "start")
      tl.to(
        `#indicator`,
        {
          opacity: 0,
          visibility: "hidden",
          width: 0,
        },
        "start"
      )

      tl.to(
        `[data-link-pos="${lastSection}"]`,
        {
          color: "rgba(255, 255, 255, 0.45)",
          duration: 0.5,
          ease: "power1.out",
        },
        "start"
      )

      tl.to(`#para`, { opacity: 0, duration: 0.25, ease: "power1.out" }, "start")

      return tl
    }

    function second() {
      let tl = gsap.timeline()

      tl.add("one")
      tl.to(`[data-link-pos="${currentSection}"]`, { color: "white", duration: 0.5, ease: "power1.out" }, "one")

      tl.to(
        `#para`,
        {
          visibility: "hidden",
        },
        "one"
      )
    }

    function three() {
      let tl = gsap.timeline()
      tl.add("two")

      tl.to(`[data-indicator="${currentSection}"]`, { visibility: "visible", opacity: 1 }, "two")
      tl.to(
        `[data-para="${currentSection}"]`,
        {
          visibility: "visible",
        },
        "two"
      )
    }

    function four() {
      let tl = gsap.timeline()
      tl.add("three")
      tl.to(`[data-para="${currentSection}"]`, { opacity: 1, duration: 0.5, ease: "power1.out" }, "three")
    }

    let master = gsap.timeline({ paused: true })
    master.add(first())
    master.add(second())
    master.add(three())
    master.add(four())
    master.play()
  }

  componentDidMount() {
    console.log(this.state.sections)
    if (this.props.mobile === false) {
      const linkSection = document.querySelector("#linkDiscover")
      const cursor = document.querySelector("#cursor")
      if (window.innerWidth > 768) {
        linkSection.addEventListener("mousemove", e => {
          cursor.style.top = e.pageY - e.target.getBoundingClientRect().top + "px"
          cursor.style.left = e.pageX - e.target.getBoundingClientRect().left + "px"
        })

        const indicators = document.querySelectorAll("#indicator")
        indicators.forEach(ind => {
          ind.style.width = `0%`
        })

        setTimeout(() => {
          const video = document.querySelector("#video-discover")
          let numberOfSections = indicators.length

          let total = 100
          let breaks = total / numberOfSections

          this.setState({ breaks: breaks })

          if (window.innerWidth > 768) {
            video.addEventListener("timeupdate", () => {
              let sectLength = parseInt(video.duration, 10) / numberOfSections

              const currentSection = indicators[this.state.sectionNum - 1]

              let percentage = (video.currentTime / video.duration) * 100
              const distnaceTravelled = (this.state.sectionNum - 1) * breaks

              let width = (percentage - distnaceTravelled) * numberOfSections

              if (this.state.lastSection === false) {
                let tl = gsap.timeline()
                tl.add("start")

                tl.to(currentSection, { width: `${width}%`, ease: "linear", duration: 0.5 }, "start")
                if (video.currentTime > sectLength * this.state.sectionNum - 1 && this.state.sectionNum < 5) {
                  let tl = gsap.timeline({ paused: true })
                  tl.add("one")
                  tl.to("#para", { opacity: 0, duration: 0.25, ease: "Power1.out" }, "one")
                  tl.play()
                }

                if (video.currentTime > sectLength * this.state.sectionNum && this.state.sectionNum < 5) {
                  this.setState({ sectionNum: this.state.sectionNum + 1 }, () => {
                    this.transiton(this.state.sectionNum - 1, this.state.sectionNum)
                  })
                } else if (video.currentTime < 10 && this.state.sectionNum >= 5) {
                  this.setState({ sectionNum: 1 }, () => {
                    this.transiton(5, 1)
                  })
                }
              }
            })
          }
        }, 1000)
      }
    }
  }
  componentWillUnmount() {
    const indicators = document.querySelectorAll("#indicator")
    let numberOfSections = indicators.length

    const video = document.querySelector("#video-discover")
    if (window.innerWidth > 768) {
      video.removeEventListener("timeupdate", () => {
        let sectLength = parseInt(video.duration, 10) / numberOfSections
        let total = 100
        let breaks = total / numberOfSections

        const currentSection = indicators[this.state.sectionNum - 1]

        let percentage = (video.currentTime / video.duration) * 100
        const distnaceTravelled = (this.state.sectionNum - 1) * breaks

        let width = (percentage - distnaceTravelled) * numberOfSections

        if (this.state.lastSection === false) {
          let tl = gsap.timeline()
          tl.add("start")

          tl.to(currentSection, { width: `${width}%`, ease: "linear", duration: 0.5 }, "start")
          if (video.currentTime > sectLength * this.state.sectionNum - 1 && this.state.sectionNum < 5) {
            let tl = gsap.timeline({ paused: true })
            tl.add("one")
            tl.to("#para", { opacity: 0, duration: 0.25, ease: "Power1.out" }, "one")
            tl.play()
          }

          if (video.currentTime > sectLength * this.state.sectionNum && this.state.sectionNum < 5) {
            this.setState({ sectionNum: this.state.sectionNum + 1 }, () => {
              this.transiton(this.state.sectionNum - 1, this.state.sectionNum)
            })
          } else if (video.currentTime < 10 && this.state.sectionNum >= 5) {
            this.setState({ sectionNum: 1 }, () => {
              this.transiton(5, 1)
            })
          }
        }
      })
    }
  }

  navVideo(e) {
    e.target.style.with = 0
    const indicators = document.querySelectorAll("#indicator")
    const video = document.querySelector("#video-discover")
    let sectLength = parseInt(video.duration, 10) / indicators.length
    let num = parseInt(e.target.getAttribute("data-link-pos"), 10)

    this.setState({ lastSection: this.state.sectionNum }, () => {
      this.setState({ sectionNum: num }, () => {
        video.currentTime = (this.state.sectionNum - 1) * sectLength + 0.3
        this.transiton(this.state.lastSection, this.state.sectionNum)

        this.setState({ lastSection: false })
      })
    })
  }

  render() {
    const titles = this.state.sections.map((section, index) => {
      return (
        <span key={index} className={"absolute nav  text-center line-height-alt " + (index !== 0 ? "o-0" : "")} data-cursor={index + 1}>
          The
          <br />
          {section.short}
        </span>
      )
    })

    /*================================================================================================================================
     *
     * desktop
     *
     */

    const navLinks = this.state.sections.map((section, index) => {
      return (
        <button key={index} style={{ width: `20%` }} className='flex flex-center-h c-p ' onClick={this.navVideo}>
          <span className={`nav linkIndicator ` + (index !== 0 ? "colorWhiteL" : "")} data-link-indicator data-link-pos={index + 1}>
            {section.name}
          </span>
        </button>
      )
    })
    const paras = this.state.sections.map((section, index) => {
      return (
        <div key={index} className={` fade absolute auto  text-large-orbikular c-white  m-w-800  ` + (index !== 0 ? "o-0" : "")} id='para' data-para={index + 1}>
          <p>{section.text}</p>
        </div>
      )
    })

    const indicators = this.state.sections.map((section, index) => {
      return (
        <div key={index} className='flex-grow-1 flex al-start flex-r over-h w-20-p m-w-20-p '>
          <div className={`h-100-p back-white m-w-100-p  ` + (index + 1 !== this.state.sectionNum ? " o-p-0" : "")} id='indicator' data-indicator={index + 1}></div>
        </div>
      )
    })

    /*================================================================================================================================
     *
     * Mobile
     *
     */

    const titleSection = this.state.sections.map((section, index) => {
      return (
        <div key={index} className={index === this.state.currentSectionMobile ? "absolute h-100-p fade-1-v-e d-1" : "o-p-0 absolute h-100-p fade-1-v-1  "}>
          <p className='nav m-b-30 '>{section.name}</p>
          <h1 className='c-white text-left m-b-30 disc'>{section.text}</h1>
          <LinktoFormButton classNameDiv='c-white' classNameArrow='f-white' text='Learn More' className='m-b-0-br ' to={`/${section.page}`} />
        </div>
      )
    })

    const menuSlider = this.state.sections.map((section, index) => {
      return (
        <button
          key={index}
          className='flex flex-r relative p-r-15 '
          onClick={() => {
            this.setState({ currentSectionMobile: index })
          }}
        >
          <div className={index === this.state.currentSectionMobile ? "nav c-white" : "white-grey nav "}>{section.name}</div>
        </button>
      )
    })
    const images = () => {
      return (
        <>
          <video className={this.state.currentSectionMobile === 0 ? "w-100-p h-100-p bg-image fade-1-v-e  absolute c-r" : " w-100-p h-100-p absolute c-r  bg-image fade-1-v-1 o-p-0"} muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.sections[0].videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
          <video className={this.state.currentSectionMobile === 1 ? "w-100-p h-100-p bg-image fade-1-v-e  absolute c-r" : " w-100-p h-100-p absolute c-r  bg-image fade-1-v-1 o-p-0"} muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.sections[1].videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
          <video className={this.state.currentSectionMobile === 2 ? "w-100-p h-100-p bg-image fade-1-v-e  absolute c-r" : " w-100-p h-100-p absolute c-r  bg-image fade-1-v-1 o-p-0"} muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.sections[2].videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
          <video className={this.state.currentSectionMobile === 3 ? "w-100-p h-100-p bg-image fade-1-v-e  absolute c-r" : " w-100-p h-100-p absolute c-r  bg-image fade-1-v-1 o-p-0"} muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.sections[3].videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
          <video className={this.state.currentSectionMobile === 4 ? "w-100-p h-100-p bg-image fade-1-v-e  absolute c-r" : " w-100-p h-100-p absolute c-r  bg-image fade-1-v-1 o-p-0"} muted='muted' autoPlay loop={true} playsInline={true}>
            <source src={this.state.sections[4].videoUrl} type='video/mp4' />
            <track kind='captions'></track>
          </video>
        </>
      )
    }

    const section = () => {
      if (this.props.mobile !== true) {
        return (
          <div className='h-100-vh w-100-vw relative flex flex-r al-end ' id='discoverHome'>
            <button
              onMouseEnter={e => {
                this.hover(e)
              }}
              onMouseLeave={e => {
                this.hoverOut(e)
              }}
              className=' h-100-vh w-100-vw z-4  flex   absolute '
              id='linkDiscover'
            >
              <PageLinkFade className='h-100-p w-100-p' to={`/${this.state.sections[this.state.sectionNum - 1].page}`}></PageLinkFade>
            </button>
            <div className='cursor flex-center-h flex-center-v z-5' id='cursor'>
              {titles}
            </div>
            <div className={"w-100-p h-100-p z-3 absolute"} data-link-image id='discoverGame'>
              <div className='absolute z-5 auto h-100-p w-100-p flex flex-center-h flex-center-v'>{paras}</div>

              <video className='bg-image z-4' muted='muted' autoPlay loop playsInline={true} id='video-discover'>
                <source src={this.state.fullVideo} type='video/mp4' />
                <track kind='captions'></track>
              </video>
            </div>

            <div className='flex flex-c  z-6 relative w-100-p  m-w-800 m-l-auto m-r-auto  '>
              <div className='w-100-p h-1 back-white-tran flex flex-r' id='indi-wrap'>
                {indicators}
              </div>
              <div className='flex flex-r p-b-20 p-t-10'>{navLinks}</div>
            </div>
          </div>
        )
      } else {
        return (
          <>
            <div className='absolute h-100-p w-100-p bright-75'>{images()}</div>
            <div className='h-100-vh-br p-30 flex flex-c flex-s-b relative'>
              <div className={`relative`}>{titleSection}</div>

              <div className='relative '>
                <div className=' flex flex-r'>
                  <div className='  w-1  back-white-tran m-r-15'>
                    <div className='back-white w-100-p h-20-p trasn-animation-m' style={{ transform: `translateY(${this.state.currentSectionMobile * 100}%)` }}></div>
                  </div>
                  <div>{menuSlider}</div>
                </div>
              </div>
            </div>
          </>
        )
      }
    }

    return <>{section()}</>
  }
}
